<template>
  <div class="benefits-items animated fadeIn flex flex-wrap justify-start">
    <div v-for="item in cards" :key="item.id" class="w-1/2 mb-90 flex-none flex justify-center">
      <component :is="componentType" v-bind="item.card" :theme="theme" />
    </div>
  </div>
</template>

<script>
import AppIconCardHorizontal from '@/components/AppIconCardHorizontal.vue'
import AppIconCardVertical from '@/components/AppIconCardVertical.vue'

export default {
  name: 'BenefitsItems',
  components: {
    AppIconCardHorizontal,
    AppIconCardVertical,
  },
  props: {
    cards: {
      type: Array,
      required: true,
    },
    isVertical: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String,
    },
  },
  computed: {
    componentType() {
      return this.isVertical ? 'AppIconCardVertical' : 'AppIconCardHorizontal'
    },
  },
}
</script>
