<template>
  <div
    class="app-icon-card-horizontal text-white"
    :class="[getRootCssClasses, theme]"
    @click="redirectTo(!soon && url)"
  >
    <div class="flex flex-nowrap info" :class="getCssClasses">
      <img class="image rounded-90 flex-none" :src="image.src" :alt="image.title" />
      <div class="text-wrapper text-left ml-24">
        <h2 class="title mb-8 font-semibold text-20 leading-normal">{{ title }}</h2>
        <p class="description mb-14 leading-138" v-html="text"></p>
        <app-soon v-if="soon" :is-yellow="true" />
      </div>
    </div>
  </div>
</template>

<script>
import redirectTo from '@/shared/utils/redirectTo'

export default {
  name: 'AppIconCardHorizontal',
  components: {
    AppSoon: () => import('@/components/AppSoon.vue'),
  },
  props: {
    image: {
      required: true,
      type: Object,
    },
    title: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
    soon: {
      default: false,
      type: Boolean,
    },
    url: {
      type: String,
    },
    theme: {
      type: String,
      default: 'light',
    },
  },
  computed: {
    getRootCssClasses() {
      return {
        'cursor-pointer': !this.soon,
      }
    },
    getCssClasses() {
      return {
        'has-soon': this.soon,
      }
    },
  },
  methods: {
    redirectTo,
  },
}
</script>

<style lang="scss" scoped>
.app-icon-card-horizontal {
  width: 100%;
  max-width: 407px;
  min-height: 179px;

  &.dark {
    color: theme('colors.white');
  }

  .info {
    &.has-soon {
      opacity: 0.6;
    }
  }

  .image {
    width: 98px;
    height: 98px;
  }

  .text-wrapper {
    max-width: 285px;
  }
}
</style>
